<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At the elevation of Bear Lake in Rocky Mountain National Park, 2900 m, the partial pressure
        of oxygen is
        <stemble-latex content="$0.14\,\text{atm.}$" />
        What is the molarity of dissolved oxygen in the lake?
        <stemble-latex content="$\text{K}_\text{H} = 1.3\times10^{-3}\,\text{M/atm.}$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\ce{O2}:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1220S4Q2',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
